import * as PropTypes from 'loudly-shared/common/proptypes';

export * from 'loudly-shared/common/proptypes';

export const withTheme = {
  theme: PropTypes.shape({
    primaryColor: PropTypes.string.isRequired,
    secondaryColor: PropTypes.string.isRequired,
  }).isRequired,
};
