export { default as compose } from 'lodash/fp/compose';

export { default as memoize } from 'lodash/memoize';

export { default as throttle } from 'lodash/throttle';

export { default as debounce } from 'lodash/debounce';

/**
 * add a one time event listener
 *
 * @param {EventSource} eventSource
 * @param {string} eventName
 * @param {Function} fn
 */
export function addEventOnceListener(eventSource, eventName, fn) {
  const listener = (...args) => {
    eventSource.removeEventListener(eventName, listener);
    fn(...args);
  };
  eventSource.addEventListener(eventName, listener);
}

/**
 * generates a promise that fulfills once an event is fired
 *
 * @param {EventSource} eventSource
 * @param {string} eventName
 * @returns {Promise<Event>}
 */
export function onceEventAsPromise(eventSource, eventName) {
  return new Promise((resolve) =>
    addEventOnceListener(eventSource, eventName, resolve),
  );
}

/**
 * URL regular expression
 */
const URL_RE = /^https?:\/\//;

/**
 *
 * @param {string} value
 * @returns {boolean}
 */
export function isUrl(value) {
  return URL_RE.test(value);
}

/**
 * Blob URL regular expression
 */
const BLOB_URL_RE = /^blob:https?:\/\//;

/**
 *
 * @param {string} value
 * @returns {boolean}
 */
export function isBlobUrl(value) {
  return BLOB_URL_RE.test(value);
}

/**
 *
 * @param {string} value
 * @returns {boolean}
 */
export function isString(value) {
  return typeof value === 'string' || value instanceof String;
}

/**
 * Use normalization to decompose a string and return the canonical part.
 * It will decompose ê to ['e', '^']. The '^' will then be removed and only
 * 'e' will be returned.
 *
 * For example if you pass 'Bïkê' it will become 'Bike'.
 *
 * For this we use the Normalizaiion Form D (Canonical Decomposition).
 *
 * @see http://unicode.org/reports/tr15/
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/normalize#Parameters
 *
 * @param {string} string - String to normalize.
 * @returns {string} - Normalized string.
 */

export function normalizeString(string = '') {
  if (isString(string)) {
    return string.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }
  return string;
}

/**
 * Enable horizontal scroll on click
 * @param {HTMLElement} container
 */
export function makeHorizontalScrollOnClick(container) {
  let isDown = false;
  let startX;
  let scrollLeft;

  container.addEventListener('mousedown', (e) => {
    isDown = true;
    startX = e.pageX - container.offsetLeft;
    scrollLeft = container.scrollLeft;
  });

  container.addEventListener('mouseleave', () => {
    isDown = false;
  });

  container.addEventListener('mouseup', () => {
    isDown = false;
  });

  container.addEventListener('mousemove', (e) => {
    if (!isDown) return;
    e.preventDefault();
    const x = e.pageX - container.offsetLeft;
    const walk = (x - startX) * 3; // scroll-fast
    // eslint-disable-next-line no-param-reassign
    container.scrollLeft = scrollLeft - walk;
  });
}

// Get competences of all the questions in the assignment
export const getAssignmentCompetences = (questions) => {
  const competences = [];
  let uniqueCompetences = [];
  questions.map((question) => competences.push(question.competenceId));

  if (competences.length) {
    uniqueCompetences = competences.filter(
      (item, i, ar) => ar.indexOf(item) === i,
    );
  }

  return uniqueCompetences;
};
