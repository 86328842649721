import styled from 'styled-components';

/**
 * Component for styled body text
 * @component
 * @property [Large] - Large size text
 * @property [Medium] - Medium size text
 * @property [Small] - Small size text
 * @property [fontColor] - Color of text
 * @property [fontWeightBold] - Weight of text (value is optional)
 * @example <Text Large>Lorem Ipsum</Text>
 * @example <Text Medium fontColor={color.appleGreen}>Lorem Ipsum</Text>
 * @example <Text Small fontWeightBold>Lorem Ipsum</Text>
 * @example <Text fontWeightBold="1000">Lorem Ipsum</Text>
 */
const Text = styled.p`
  ${({ fontColor }) => fontColor && `color: ${fontColor}`};
  font-weight: ${({ fontWeightBold }) => (fontWeightBold ? 600 : 'normal')};
  margin: 0;

  ${({ Large, Medium, Small }) => {
    switch (true) {
      case Large:
        return `
        font-size: 1.25rem;
        line-height: 1.875rem;`;
      case Medium:
        return `
        font-size: 1.125rem;
        line-height: 1.6875rem;
      `;
      case Small:
        return `
        font-size: 1rem;
        line-height: 1.5rem;
      `;
      default:
        return `
        font-size: 1rem;
        line-height: 1.5rem;
      `;
    }
  }}
`;

export default Text;
