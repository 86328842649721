import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import * as PropTypes from 'utils/proptypes';

const ResetScrollOnRouteChange = ({ location }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return null;
};

ResetScrollOnRouteChange.propTypes = {
  ...PropTypes.withRouter,
};

export default withRouter(ResetScrollOnRouteChange);
