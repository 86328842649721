export const BRAND = {
  LOUDLY: 'loudly',
  LEARNCLEARLY: 'learnclearly',
  AURIS: 'auris',
};

export const LANGUAGE_CODE = {
  EN: 'en',
  NL: 'nl',
  DE: 'de',
  FR: 'fr',
};

export const USER_ROLE = {
  DEFAULT: 'default',
  STUDENT: 'student',
  TEACHER: 'teacher',
};

export const USER_GENDER = {
  MALE: 'male',
  FEMALE: 'female',
  OTHER: 'other',
};

export const SCHOOL_TYPE = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
};

export const ASSIGNMENT_MODE = {
  PRACTICE: 'practice',
  SOCIAL: 'social',
  COMPETITION: 'competition',
};

export const TEST_MODE = {
  PRETEST: 'pretest',
  POSTTEST: 'posttest',
  POSTPONED_POSTTEST: 'postponed_posttest',
};

export const ASSIGNMENT_COMPETENCE_ID = {
  VOCABULARY_AND_UNDERSTANDING: 'VOCABULARY_AND_UNDERSTANDING',
  VOCABULARY_AND_SPELLING: 'VOCABULARY_AND_SPELLING',
  DICTATION: 'DICTATION',
  VOCABULARY_AND_SPEAKING: 'VOCABULARY_AND_SPEAKING',
  PRONUNCIATION: 'PRONUNCIATION',
  SPEECH_THERAPY: 'SPEECH_THERAPY',
  MIXED: 'MIXED',
  FLASHCARD: 'FLASHCARD',
};

export const QUESTION_COMPETENCE_ID = {
  VOCABULARY_AND_UNDERSTANDING: {
    id: 'VOCABULARY_AND_UNDERSTANDING',
    name: 'Vocabulary & Understanding',
  },
  VOCABULARY_AND_SPELLING: {
    id: 'VOCABULARY_AND_SPELLING',
    name: 'Vocabulary & Spelling',
  },
  VOCABULARY_AND_SPEAKING: {
    id: 'VOCABULARY_AND_SPEAKING',
    name: 'Vocabulary & Speaking',
  },
  SPEECH_THERAPY: {
    id: 'SPEECH_THERAPY',
    name: 'Speech Therapy',
  },
  FLASHCARD: {
    id: 'FLASHCARD',
    name: 'Flashcard',
  },
};

export const PARTY_ROOM_STATE = {
  PENDING: 'pending',
  ONGOING_QUESTION: 'ongoing.question',
  ONGOING_ANSWER: 'ongoing.answer',
  DONE: 'done',
};

export const PAYMENT_STATUS = {
  OPEN: 'open',
  PAID: 'paid',
  FAILED: 'failed',
  EXPIRED: 'expired',
  CANCELED: 'canceled',
};

export const LANGUAGE_MAPPING = {
  [LANGUAGE_CODE.EN]: {
    code: LANGUAGE_CODE.EN,
    flag: 'gb',
    label: 'English',
    nativeLabel: 'English',
    country: 'England',
    itslLanguageCodeuageCode: 'eng',
  },
  [LANGUAGE_CODE.NL]: {
    code: LANGUAGE_CODE.NL,
    flag: LANGUAGE_CODE.NL,
    label: 'Dutch',
    nativeLabel: 'Nederlands',
    country: 'Netherlands',
    itslLanguageCode: 'nld',
  },
  [LANGUAGE_CODE.DE]: {
    code: LANGUAGE_CODE.DE,
    flag: LANGUAGE_CODE.DE,
    label: 'German',
    nativeLabel: 'Deutsch',
    country: 'Germany',
    itslLanguageCode: 'deu',
  },
  [LANGUAGE_CODE.FR]: {
    code: LANGUAGE_CODE.FR,
    flag: LANGUAGE_CODE.FR,
    label: 'French',
    nativeLabel: 'Français',
    country: 'France',
    itslLanguageCode: 'fra',
  },
};

export const ITSLANGUAGE_GENDER_MAPPING = {
  [USER_GENDER.MALE]: 'm',
  [USER_GENDER.FEMALE]: 'f',
  [USER_GENDER.OTHER]: 'x',
};

export const PAYMENT_METHOD = 'ideal';

// VAT_RATE is the Dutch Value Added tax rate which stands at 21%.
export const VAT_RATE = 0.21;

export const MEDIA_TYPE = {
  IMAGE: 'Image',
  VIDEO: 'Video',
  AUDIO: 'Audio',
};

export const PIXABAY_IMAGES_TYPE = {
  PHOTO: 'photo',
  VECTOR: 'vector',
};

export const GUEST_USER = 'guest';

export const DEFAULT_SCHOOL_ID = 'default';

export const DEFAULT_SCHOOL_NAME = 'Default School';

export const ASSESSMENT_OPTIONS_MAPPING = {
  CHECK_UPPER_CASE_LOWER_CASE: 'Check Uppercase/Lowercase',
  SHOW_CORRECT_ANSWER: 'Show correct answer',
  AUTO_NEXT_CARD: 'Automatically move to next flashcard',
  ANSWER_WITHIN_TIME_LIMIT: 'Answer within time limit',
  AUTOMATIC_SPEECH_ASSESSMENT: 'Automatic Speech Assessment',
  CHECK_PUNCTUATION: 'Check Punctuation',
  PRACTICE_ONLY_WRONG_WORDS: 'Practice only wrong words',
  PRACTICE_UNTILL_CORRECT: ' Practice untill correct',
};
